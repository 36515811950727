<template>
    <main>

      <div v-if="loading && !requiredFields.id">
        <b-skeleton width="100%" height="400px" />
      </div>
      <section v-else class="required-fields">
        <div class="required-fields__title-container">
          <span class="required-fields-title">Obrigatoriedade de campos <InfoIcon id="info-badge" class="cursor-pointer" /></span>
          <b-tooltip v-if="!loading" target="info-badge" triggers="hover" placement="top" custom-class="custom-fields-tooltip">
            Selecione os campos que deverão ser obrigatórios para gerar as guias relacionadas a esta clínica. Os campos não selecionados serão opcionais.
          </b-tooltip>
        </div>
        <div class="required-fields__category">
          <span class="required-fields__category__title">Múltiplas guias</span>
          <div class="required-fields__category__items">
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.provider_guide">
              <span>Nº da guia no prestador</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.wallet_number">
              <span>Nº da carteira</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.password">
              <span>Senha</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.wallet_expiration_date">
              <span>Validade da carteira</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.beneficiary_name">
              <span>Nome do beneficiário</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.accident_indication">
              <span>Indicação de acidente</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.attendance_regime">
              <span>Regime de atendimento</span>
            </div>
          </div>
        </div>
        <div class="required-fields__category">
          <span class="required-fields__category__title">Procedimentos</span>
          <div class="required-fields__category__items">
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.execution_date">
              <span>Data do atendimento</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.start_time">
              <span>Hora inicial</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.end_time">
              <span>Hora final</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.pathway">
              <span>Via</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.technique">
              <span>Técnica</span>
            </div>
          </div>
        </div>
        <div class="required-fields__category">
          <span class="required-fields__category__title">Guia de consulta</span>
          <div class="required-fields__category__items">
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.operator_guide">
              <span>Nº da guia operadora</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.professional_id">
              <span>Profissional executante</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.attendance_date">
              <span>Data do atendimento</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.attedance_type">
              <span>Tipo de consulta</span>
            </div>
          </div>
        </div>
        <div class="required-fields__category">
          <span class="required-fields__category__title">Guia SP/SADT</span>
          <div class="required-fields__category__items">
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.main_tab_number">
              <span>Nº da guia principal</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.contractor_name_requesting">
              <span>Contratado solicitante</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.authorization_date">
              <span>Data da autorização</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.password_expiration_date">
              <span>Validade da senha</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.request_date">
              <span>Data da solicitação</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.attendance_character">
              <span>Caráter do atendimento</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.clinical_indication">
              <span>Indicação clínica</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.attendance_type">
              <span>Tipo de atendimento</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.attendance_regime">
              <span>Regime de atendimento</span>
            </div>
          </div>
        </div>
        <div class="required-fields__category">
          <span class="required-fields__category__title">Guia Honorários</span>
          <div class="required-fields__category__items">
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.hosp_req">
              <span>Nº da guia solic. intern.</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.performing_contractor_name">
              <span>Contratado executante</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.invoicing_start_day">
              <span>Data início faturamento</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.invoicing_end_day">
              <span>Data final faturamento</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.issue_date">
              <span>Data de emissão</span>
            </div>
          </div>
        </div>
      </section>
    </main>
</template>
<script>
    import { mapState } from 'vuex'
    import api from '@/modules/tiss/manageGuides/api'
    import { getCurrentClinic } from '@/utils/localStorageManager'
    export default {
        components: {
            InfoIcon: () => import('@/assets/icons/info-icon.svg')
        },
        computed: {
            ...mapState('manageGuides', ['requiredFields']),            
        },
        data() {
            return {
                loading: false,
                clinic: getCurrentClinic(),
            }
        },
        methods: {
            updateTissGuideRequiredFields() {
                this.loading = true
                api.updateTissGuideRequiredFields(this.requiredFields.id, this.requiredFields)
                    .then(() => {})
                    .catch(err => this.$toast.error(err.message))
                    .finally(() => (this.loading = false))
            }
        },
        watch: {
            requiredFields: {
                async handler() {
                    this.updateTissGuideRequiredFields()
                },
                deep: true
            }
        }
    }
</script>
<style lang="scss" scoped>
.required-fields {
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 16px;

  &__title-container {
    display: flex;
    justify-content: space-between;

    .required-fields-title {
      font-size: 16px;
      font-weight: 600;
      color: #142666;
    }

    .delete-settings {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #305BF2;
      cursor: pointer;

      svg {
        width: 16px;
        height: 20px;
        fill: #305BF2;
      }
    }
  }

  &__category {
    &__title {
      font-weight: 600;
    }

    padding: 8px 16px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__items {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 8px;

      .item {
        display: flex;
        align-items: center;
        gap: 8px;
        min-width: 208px;

        input {
          width: 16px;
          height: 16px;
        }

        span {
          display: flex;
          height: 24px;
          flex-direction: column;
          justify-content: center;
          font-weight: 400;
        }
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>